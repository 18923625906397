import React from 'react'
import { observer } from 'mobx-react'
import { Text } from 'comps/tweet/text'
import { Media } from 'comps/tweet/media'
import { Quote } from 'comps/tweet/quote'
import { Footer } from 'comps/tweet/footer'

export const Tweet = observer(({ data }) => {
  return (
    <div className='tweet p-4 bg-gray-800 rounded shadow-xl'>

      <Text data={data} />

      {data.extended_entities?.media ? (
        <Media media={data.extended_entities.media} />
      ) : (data.entities?.media && (
        <Media media={data.entities.media} />
      ))}

      {data.quoted_status && (
        <Quote data={data.quoted_status} />
      )}

      <Footer data={data} />

    </div>
  )
})
