import React from 'react'

export function Video ({ media, gif }) {
  const { variants } = media[0].video_info

  return (
    <video className='w-full mb-4' autoPlay controls={!gif} loop={gif}>
      {variants.map((variant) => (
        <source key={variant.url} src={variant.url} type={variant.content_type} />
      ))}
    </video>
  )
}
