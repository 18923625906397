import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'

export function Images ({ media }) {
  const [index, selectIndex] = useState(0)
  const [open, setOpen] = useState(false)

  const toggleModal = (i) => {
    selectIndex(i)
    setOpen(!open)
  }

  const images = media.map(m => ({ source: m.media_url_https }))
  const width = images.length === 1 ? 'w-full' : (images.length === 3 ? 'w-1/3' : 'w-1/2')

  return (
    <>
      <div className='flex flex-wrap justify-center overflow-hidden mb-4'>
        {images.map((img, j) => (
          <img
            key={img.source}
            src={img.source}
            alt='media'
            onClick={() => toggleModal(j)}
            className={`tweet-image ${width} object-cover overflow-hidden cursor-pointer`}
          />
        ))}
      </div>
      <ModalGateway>
        {open && (
          <Modal onClose={toggleModal}>
            <Carousel
              currentIndex={index}
              views={images}
            />
          </Modal>
        )}
      </ModalGateway>
    </>
  )
}
