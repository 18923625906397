import React from 'react'
import { Video } from 'comps/tweet/video'
import { Images } from 'comps/tweet/images'

export function Media ({ media }) {
  switch (media[0].type) {
    case 'photo':
      return <Images media={media} />
    case 'video':
      return <Video media={media} gif={false} />
    case 'animated_gif':
      return <Video media={media} gif />
    default:
      return <div />
  }
}
