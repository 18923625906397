import React from 'react'
import moment from 'moment'

function num (x) {
  if (isNaN(x)) return x

  if (x < 9999) {
    return x
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + 'K'
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + 'M'
  }

  if (x < 1000000000) {
    return Math.round((x / 1000000)) + 'M'
  }

  if (x < 1000000000000) {
    return Math.round((x / 1000000000)) + 'B'
  }

  return '1T+'
}

export function Footer ({ data }) {
  return (
    <div className='flex justify-between items-center px-2'>
      <span className='flex justify-start items-center text-gray-300'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 mr-2 fill-current'
          viewBox='0 -54 511.999 511'
        >
          <path
            d='M287.613 283.176a28.934 28.934 0 00-21.96-10.082h-85.645v-64.742h20.363c9.57 0 18.508-4.536 23.91-12.133 7.774-10.934 7.086-25.64-1.668-35.766l-85.87-99.32a28.943 28.943 0 00-21.903-10.016 28.95 28.95 0 00-21.91 10.02L7.062 160.453c-8.753 10.125-9.44 24.828-1.671 35.762 5.402 7.601 14.343 12.137 23.918 12.137h20.363v129.914c0 35.933 29.234 65.168 65.168 65.168h212.75c9.555 0 18.488-4.524 23.887-12.102 7.777-10.906 7.12-25.602-1.594-35.738zm0 0M506.602 207.715c-5.403-7.598-14.34-12.137-23.914-12.137h-20.36V65.668C462.328 29.734 433.094.5 397.156.5H184.414c-9.555 0-18.484 4.523-23.887 12.102-7.78 10.906-7.125 25.605 1.59 35.738l62.27 72.418a28.93 28.93 0 0021.96 10.078h85.645v64.742H311.63c-9.57 0-18.508 4.54-23.914 12.14-7.77 10.93-7.082 25.637 1.672 35.762l85.867 99.32a28.958 28.958 0 0021.906 10.016 28.924 28.924 0 0021.906-10.02l85.871-99.32c8.754-10.124 9.438-24.831 1.665-35.761zm0 0'
          />
        </svg>
        <span className='mr-8'>{num(data.retweet_count)}</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 mr-2 fill-current'
          viewBox='0 -28 512 512'
        >
          <path
            d='M471.383 44.578C444.879 15.832 408.512 0 368.973 0c-29.555 0-56.621 9.344-80.45 27.77C276.5 37.07 265.605 48.45 256 61.73c-9.602-13.277-20.5-24.66-32.527-33.96C199.648 9.344 172.582 0 143.027 0c-39.539 0-75.91 15.832-102.414 44.578C14.426 72.988 0 111.801 0 153.871c0 43.3 16.137 82.938 50.781 124.742 30.992 37.395 75.535 75.356 127.117 119.313 17.614 15.012 37.579 32.027 58.309 50.152A30.023 30.023 0 00256 455.516c7.285 0 14.316-2.641 19.785-7.43 20.73-18.129 40.707-35.152 58.328-50.172 51.575-43.95 96.117-81.906 127.11-119.305C495.867 236.81 512 197.172 512 153.867c0-42.066-14.426-80.879-40.617-109.289zm0 0'
          />
        </svg>
        <span className='mr-8'>{num(data.favorite_count)}</span>
        <span>{moment(data.created_at).format('DD/MM/YYYY')}</span>
      </span>
      <span className='flex justify-end items-center text-blue-400'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-6 h-6 fill-current'
          viewBox='0 0 512 512'
        >
          <path
            d='M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z'
          />
        </svg>
      </span>
    </div>
  )
}
