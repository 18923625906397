import React from 'react'
import { Home } from 'views/home'
import { Game } from 'views/game'
import { Over } from 'views/over'
import { Route, Switch } from 'react-router-dom'

export const App = () => {
  return (
    <Switch>
      <Route path='/over' component={Over} />
      <Route path='/game' component={Game} />
      <Route component={Home} />
    </Switch>
  )
}
