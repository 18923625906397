import { observer } from 'mobx-react'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import Poop from 'assets/images/poop.png'
import { Redirect } from 'react-router-dom'
import { useStores } from 'hooks/use-stores'

export const Home = observer(() => {
  const { gameStore } = useStores()

  useEffect(() => {
    async function getUsers () {
      await gameStore.getUsers()
    }

    getUsers()
  }, [])

  const remainingUsersCount = gameStore.selectedDiff - gameStore.selectedUsers.length

  const startGame = async () => await gameStore.startGame()
  const setDifficulty = ({ target }) => gameStore.setDifficulty(target.value)
  const isUserSelected = ({ id_str: id }) => gameStore.selectedUsers.includes(id)

  if (gameStore.playing) {
    return <Redirect to='/game' />
  }

  return (
    <div className='max-w-3xl mx-auto py-8 px-4'>

      <h1 className='font-hand text-center text-3xl mt-8 mb-2'>
          qui a tweeté cette merde?
      </h1>

      <h3 className='text-center text-xl text-gray-500 align-middle mb-8'>
          featuring la <img src={Poop} alt='caca' className='inline-block w-8 h-8' /> box
      </h3>

      <p className='mb-8 text-justify text-gray-500'>
          slt normalement je devrais expliquer le jeu ici mais comme personne va lire de toute façon flemme, fais un effort c'est pas compliqué putain
      </p>

      <div className='flex justify-around items-center mb-12'>
        <div className='w-full'>

          <p className='mb-2'>difficulté</p>

          <select onChange={setDifficulty} value={gameStore.selectedDiff} className='w-2/3 p-2 pr-4 border-transparent bg-gray-800 rounded shadow-xl'>
            <option value='2'>2 personnes</option>
            <option value='3'>3 personnes</option>
            <option value='4'>4 personnes</option>
            <option value='6'>6 personnes</option>
            <option value='10'>10 personnes</option>
          </select>

        </div>
      </div>

      <p className='mb-4'>choisis tes <del className='text-xs'>enculés</del> talents favoris</p>

      <div className='flex flex-wrap justify-between items-center mb-8'>
        {gameStore.users.map((user) => (
          <motion.div
            key={user.id_str}
            onClick={() => gameStore.selectUser(user.id_str)}
            className='flex flex-col justify-center items-center text-center w-24 p-2 mb-4'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img
              src={`/medias/${user.id_str}.jpg`}
              className='w-16 rounded-full'
              alt='profile'
            />
            {isUserSelected(user) ? (
              <div className='font-medium text-lg text-blue-400 mt-2'>
                {user.screen_name}
              </div>
            ) : (
              <div className='text-sm text-gray-500 mt-2'>
                {user.screen_name}
              </div>
            )}
          </motion.div>
        ))}
      </div>

      <div className='text-center'>

        {remainingUsersCount > 0 && (
          <div className='text-sm text-red-500 mb-4'>
              encore {remainingUsersCount} talents stp
          </div>
        )}

        <button
          type='button'
          onClick={startGame}
          disabled={remainingUsersCount > 0}
          className='px-8 py-2 font-medium text-xl text-white bg-blue-400 rounded shadow-xl hover:bg-blue-300 disabled:bg-gray-500'
        >
            c parti!!!!!!!!!!
        </button>

      </div>

    </div>
  )
})
