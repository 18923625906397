import React from 'react'
import ReactGA from 'react-ga'
import ReactDOM from 'react-dom'
import 'mobx-react-lite/batchingForReactDom'
import { BrowserRouter } from 'react-router-dom'

import 'index.css'
import * as sw from 'sw'
import { App } from 'app'
import ScrollToTop from 'comps/scroll-to-top'

/**
 * Render DOM
 */

ReactDOM.render((
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
), document.getElementById('root'))

/**
 * Register service worker
 */

sw.unregister()

/**
 * Analytics
 */

ReactGA.initialize('UA-116458051-3')
ReactGA.pageview(window.location.pathname + window.location.search)
