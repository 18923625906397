import React from 'react'
import { Text } from 'comps/tweet/text'
import { Thumbnail } from 'comps/tweet/thumbnail'

export function Quote ({ data }) {
  return (
    <a
      href={`https://twitter.com/${data.user.screen_name}/status/${data.id_str}`}
      rel='noopener noreferrer'
      target='_blank'
    >
      <div className='flex border-solid border-2 border-gray-600 p-4 mb-4 rounded'>

        {data.extended_entities?.media ? (
          <Thumbnail media={data.extended_entities.media} />
        ) : (data.entities?.media && (
          <Thumbnail media={data.entities.media} />
        ))}

        <div>
          <div>
            <span className='mr-2'>{data.user.name}</span>
            <span className='text-sm text-gray-500'>@{data.user.screen_name}</span>
          </div>
          <Text data={data} />
        </div>

      </div>
    </a>
  )
}
