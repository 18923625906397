import React from 'react'
import { Tweet } from 'comps/tweet'
import { observer } from 'mobx-react'
import BF from 'assets/images/bigflo.png'
import { Redirect } from 'react-router-dom'
import Heart from 'assets/images/heart.png'
import { useStores } from 'hooks/use-stores'
import JC from 'assets/images/jean-claude.png'
import JCG from 'assets/images/jean-claude-gold.png'
import { motion, useAnimation } from 'framer-motion'

export const Game = observer(() => {
  const { gameStore } = useStores()
  const controls = useAnimation()

  const checkUser = async ({ id_str: id }) => {
    window.scrollTo(0, 0)

    if (gameStore.checkUser(id)) {
      await gameStore.getTweetData()
    } else {
      await controls.start({
        scale: 0,
        rotate: 60
      }, {
        duration: 1.6,
        ease: 'circIn'
      })

      gameStore.goToOver()
    }
  }

  if (gameStore.gameover) {
    return <Redirect to='/over' />
  }

  if (!gameStore.playing) {
    return <Redirect to='/' />
  }

  return (
    <div className='max-w-3xl mx-auto py-8 px-4'>

      <h1 className='font-hand text-center text-3xl my-8'>
        {gameStore.isTweetLoaded && gameStore.currData.tweet.retweet_count > 500 ? (
          'qui a tweeté cette masterclass?'
        ) : (
          'qui a tweeté cette merde?'
        )}
      </h1>

      <div className='flex justify-between items-center p-4'>
        <span className='flex justify-start items-center text-xl font-medium'>
          <img className='inline-block w-8 mr-2' src={JC} alt='jean-claude' />
          <span className='mr-8'>{gameStore.currScore}</span>
          <img className='inline-block w-8 mr-2' src={JCG} alt='jean-claude' />
          <span className='text-yellow-500'>{gameStore.bestScore}</span>
        </span>
        <span className='flex justify-end items-center'>
          {gameStore.lastData.tweet ? (
            <a
              href={`https://twitter.com/${gameStore.lastData.author.screen_name}/status/${gameStore.lastData.tweet.id_str}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              <p>
                <span className='hidden sm:inline-block text-blue-400 mr-2'>
                  dernier tweet
                </span>
                <img
                  src={`/medias/${gameStore.lastData.author.id_str}.jpg`}
                  className='inline-block w-8 rounded-full'
                  alt='profile'
                />
              </p>
            </a>
          ) : (
            <a
              href='https://cutt.ly/qatcm-bonus'
              rel='noopener noreferrer'
              target='_blank'
            >
              <p>
                <span className='hidden sm:inline-block text-red-400 mr-2'>
                  obtenir un bonus
                </span>
                <img
                  src={Heart}
                  className='inline-block w-4'
                  alt='heart'
                />
              </p>
            </a>
          )}
        </span>
      </div>

      {gameStore.isTweetLoaded ? (
        <motion.div className='mb-8' animate={controls}>

          <div className='mb-8'>
            <Tweet data={gameStore.currData.tweet} />
          </div>

          <div className='flex flex-wrap justify-center items-center mb-8'>
            {gameStore.currData.choices.map((user) => (
              <motion.div
                key={user.id_str}
                onClick={() => checkUser(user)}
                className='flex flex-col justify-center items-center text-center w-24 p-2 mb-4'
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <img
                  src={`/medias/${user.id_str}.jpg`}
                  className='w-16 rounded-full'
                  alt='profile'
                />
                <div className='text-gray-500 mt-2'>
                  {user.screen_name}
                </div>
              </motion.div>
            ))}
          </div>

          {gameStore.selectedDiff === 1 && (
            <button onClick={() => checkUser({ id_str: '420' })}>
              ---> abandon
            </button>
          )}

        </motion.div>
      ) : (
        <div className='flex justify-center'>
          <img src={BF} className='w-32 py-32 anim-rotate' alt='bigflo' />
        </div>
      )}

      <div className='text-xs text-right' style={{ marginTop: '2048px' }}>
        t'as vraiment rien d'autre à foutre?
      </div>

    </div>
  )
})
