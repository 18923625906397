import QS from 'query-string'
import { action, computed, decorate, observable, reaction } from 'mobx'

const ids = [
  '867105008684339200',
  '3131750951',
  '1887691993',
  '939871993050157056',
  '1010861981845327872',
  '2721604765',
  '1200888541254995970',
  '2830147870',
  '80410029',
  '733511965',
  '1225062994415058944',
  '738776927796908032',
  '47983773',
  '3347379724',
  '1319937703916556288',
  '515697572',
  '461086357',
  '2984400398',
  '1011548162232934400',
  '1159992741620846592',
  '948237091'
]

const userAudios = {
  '939871993050157056': new window.Audio(require('assets/audio/potatoz.mp3'))
}

// const astronomiaAudio = new window.Audio(require('assets/audio/astronomia.mp3'))
// astronomiaAudio.volume = 0.3

class GameStore {
  users = []
  playing = false
  gameover = false

  currData = {}
  lastData = {}
  currTweets = []

  bestScores = {}
  selectedDiff = 2
  selectedUsers = []

  constructor () {
    this.bestScores = JSON.parse(window.localStorage.getItem('best-scores')) || {}
    this.selectedDiff = Number(window.localStorage.getItem('selected-diff')) || 2
    this.selectedUsers = (JSON.parse(window.localStorage.getItem('selected-users')) || []).filter(u => ids.includes(u))

    reaction(() => this.bestScores, (scores) => window.localStorage.setItem('best-scores', JSON.stringify(scores)))
    reaction(() => this.selectedDiff, (diff) => window.localStorage.setItem('selected-diff', String(diff)))
    reaction(() => this.selectedUsers, (users) => window.localStorage.setItem('selected-users', JSON.stringify(users)))
  }

  /**
   * API
   */

  async getUsers () {
    const res = await window.fetch('/api/get-users')
    this.users = await res.json()

    const ids = this.users.map(user => user.id_str)
    this.selectedUsers = this.selectedUsers.filter(user => ids.includes(user))
  }

  async getTweetData () {
    this.lastData = this.currData
    this.currData = {}

    const query = QS.stringify({
      users: this.selectedUsers,
      difficulty: this.selectedDiff
    })

    const res = await window.fetch(`/api/get-tweet?${query}`)
    this.currData = await res.json()
  }

  /**
   * Settings
   */

  selectUser (id) {
    if (!this.selectedUsers.includes(id)) {
      this.selectedUsers = [...this.selectedUsers, id]

      if (userAudios[id]) {
        userAudios[id].load()
        userAudios[id].play()
      }
    } else {
      this.selectedUsers = this.selectedUsers.filter(v => v !== id)

      if (userAudios[id]) {
        userAudios[id].pause()
      }
    }
  }

  setDifficulty (diff) {
    this.selectedDiff = Number(diff)
  }

  /**
   * Game
   */

  async startGame () {
    this.playing = true
    this.gameover = false
    this.currData = {}
    this.lastData = {}
    this.currTweets = []
    // astronomiaAudio.pause()

    await this.getTweetData()
  }

  checkUser (id) {
    const tweetId = this.currData?.tweet.id_str
    const authorId = this.currData?.author.id_str

    if (id === authorId) {
      if (!this.currTweets.includes(tweetId)) {
        this.currTweets = [...this.currTweets, tweetId]
      }

      return true
    } else {
      this.bestScores = {
        ...this.bestScores,
        [this.selectedDiff]: Math.max(this.currScore, this.bestScore)
      }

      // astronomiaAudio.load()
      // astronomiaAudio.play()

      return false
    }
  }

  goToOver () {
    this.playing = false
    this.gameover = true
  }

  goToHome () {
    this.playing = false
    this.gameover = false
    // astronomiaAudio.pause()
  }

  /**
   * Score
   */

  get currScore () {
    return this.currTweets.length || 0
  }

  get bestScore () {
    return this.bestScores[this.selectedDiff] || 0
  }

  get isTweetLoaded () {
    return this.currData.tweet && this.currData.author
  }
}

decorate(GameStore, {
  users: observable,
  playing: observable,
  gameover: observable,
  currData: observable,
  lastData: observable,
  currTweets: observable,
  bestScores: observable,
  selectedDiff: observable,
  selectedUsers: observable,
  getUsers: action.bound,
  getTweetData: action.bound,
  selectUser: action.bound,
  setDifficulty: action.bound,
  startGame: action.bound,
  checkUser: action.bound,
  goToOver: action.bound,
  goToHome: action.bound,
  currScore: computed,
  bestScore: computed,
  isTweetLoaded: computed
})

export { GameStore }
