import React from 'react'
import Twemoji from 'twemoji'
import Twitter from 'twitter-text'

export function Text ({ data }) {
  let {
    full_text: text,
    entities = {},
    extended_entities: extended = {}
  } = data

  if (entities?.media) {
    entities.media.forEach(m => {
      text = text.replace(m.url, '')
    })
  }

  if (extended?.media) {
    extended.media.forEach(m => {
      text = text.replace(m.url, '')
    })
  }

  if (entities?.urls && data.quoted_status) {
    entities.urls.forEach((u) => {
      if (u.expanded_url.indexOf('/status/') > -1) {
        text = text.replace(u.url, '')
      }
    })
    entities.urls = entities.urls.filter(u => u.expanded_url.indexOf('/status/') === -1)
  }

  text = Twemoji.parse(Twitter.autoLinkWithJSON(text, {
    hashtags: entities.hashtags,
    symbols: entities.symbols,
    user_mentions: entities.user_mentions,
    urls: entities.urls
  }, {
    usernameIncludeSymbol: true,
    targetBlank: true
  }), {
    className: 'tweet-emoji'
  })

  if (text.trim().length === 0) {
    return null
  }

  return (
    <p className='tweet-text whitespace-pre-wrap mb-4' dangerouslySetInnerHTML={{ __html: text }} />
  )
}
