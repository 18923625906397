import React from 'react'
import QS from 'query-string'
import { observer } from 'mobx-react'
import { motion } from 'framer-motion'
import { Redirect } from 'react-router-dom'
import { useStores } from 'hooks/use-stores'
import Coffin from 'assets/images/coffin.png'
import JC from 'assets/images/jean-claude.png'
import JCG from 'assets/images/jean-claude-gold.png'

export const Over = observer(() => {
  const { gameStore } = useStores()

  if (gameStore.playing) {
    return <Redirect to='/game' />
  }

  if (!gameStore.gameover) {
    return <Redirect to='/' />
  }

  const query = QS.stringify({
    url: 'https://qatcm.spri.dev',
    via: 'spridev',
    text: `super ma vie j'ai fait ${gameStore.currScore} à l'excellent jeu "qui a tweeté cette merde"`
  })

  return (
    <div className='max-w-3xl mx-auto py-8 px-4 flex flex-col items-center'>

      <h1 className='font-hand text-center text-3xl mt-8 mb-2'>
        perdu........
      </h1>

      <div className='flex justify-center items-center text-xl text-gray-500 align-middle mb-16'>
        <span className='mr-2'>c'était un tweet de</span>
        <a
          href={`https://twitter.com/${gameStore.currData.author.screen_name}/status/${gameStore.currData.tweet.id_str}`}
          className='text-blue-400'
          rel='noopener noreferrer'
          target='_blank'
        >
          {gameStore.currData.author.screen_name}
        </a>
        <img
          src={`/medias/${gameStore.currData.author.id_str}.jpg`}
          className='inline-block w-8 mx-2 rounded-full'
          alt='profile'
        />
      </div>

      <div className='flex justify-center items-center'>
        <span className='text-3xl mr-4'>Score: {gameStore.currScore}</span>
        {gameStore.currScore === gameStore.bestScore ? (
          <img className='inline-block w-16' src={JCG} alt='jean-claude' />
        ) : (
          <img className='inline-block w-16' src={JC} alt='jean-claude' />
        )}
      </div>

      {gameStore.currScore === gameStore.bestScore && (
        <p className='text-lg text-center text-yellow-500 mt-2'>nouveau meilleur score <span role='img' aria-label='party'>🥳</span></p>
      )}

      <button
        type='button'
        onClick={() => gameStore.startGame()}
        className='w-full sm:w-1/2 md:w-1/3 py-2 mt-16 mb-2 font-medium text-xl text-white text-center anim-rainbow rounded shadow-xl'
      >
          rejouer
      </button>

      <a
        href={`https://twitter.com/intent/tweet?${query}`}
        rel='noopener noreferrer'
        target='_blank'
        className='w-full sm:w-1/2 md:w-1/3 py-2 mb-2 font-medium text-xl text-white text-center bg-gray-800 rounded shadow-xl'
      >
          partager mon score
      </a>

      <button
        type='button'
        onClick={() => gameStore.goToHome()}
        className='w-full sm:w-1/2 md:w-1/3 py-2 mb-4 font-medium text-xl text-white text-center bg-gray-800 rounded shadow-xl'
      >
          changer des trucs
      </button>

      <a
        className='mb-2'
        href='https://twitter.com/spridev'
        rel='noopener noreferrer'
        target='_blank'
      >
        <p>
          <span className='inline-block text-blue-400 mr-2'>
            c'est moi ->
          </span>
          <img
            src='https://pbs.twimg.com/profile_images/859008850539753472/Ip5ed5S0_400x400.jpg'
            className='inline-block w-8 rounded-full'
            alt='profile'
          />
        </p>
      </a>

      <a
        className='text-sm text-gray-500'
        href='https://www.youtube.com/watch?v=ZZtjTcbyHTo'
        rel='noopener noreferrer'
        target='_blank'
      >
        le son
      </a>

      <motion.div
        className='coffin'
        animate={{
          scale: [1.2, 1.3, 1, 1, 1, 1],
          rotate: [0, 6, -6, 0]
        }}
        transition={{
          loop: 7,
          duration: 0.95,
          type: 'spring'
        }}
      >
        <img className='w-full' src={Coffin} alt='coffin' />
      </motion.div>

    </div>
  )
})
